import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codefresh/volume/bolt/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from 'docz';
import '../../dist/css/bolt-css.css';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "divider"
    }}>{`Divider`}</h1>
    <br />
    <br />
    <h2 {...{
      "id": "padrão"
    }}>{`Padrão`}</h2>
    <br />
    <Playground __position={2} __code={'() => {\n  return (\n    <div className=\"tw-flex tw-w-full tw-h-16 tw-items-center\">\n      <div className=\"tw-divider\"></div>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        return <div className="tw-flex tw-w-full tw-h-16 tw-items-center">
        <div className="tw-divider"></div>
      </div>;
      }}
    </Playground>
    <br />
    <br />
    <h2 {...{
      "id": "texto"
    }}>{`Texto`}</h2>
    <br />
    <Playground __position={5} __code={'() => {\n  return (\n    <div className=\"tw-flex tw-w-full tw-h-16 tw-items-center\">\n      <div className=\"tw-divider\">\n        <p className=\"tw-body3 tw-font-bold\">Due dates</p>\n      </div>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        return <div className="tw-flex tw-w-full tw-h-16 tw-items-center">
        <div className="tw-divider">
          <p className="tw-body3 tw-font-bold">Due dates</p>
        </div>
      </div>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      